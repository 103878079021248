<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    header="Especialidad Detalle"
    :modal="true"
    class="p-fluid"
    @hide="ocultarDialog"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-8">
          <span
            ><strong>NOMBRE: </strong> <span class="p-invalid">*</span></span
          >
          <InputText
            v-model.trim="data_consultorio.nombre"
            required="true"
            placeholder="Nombre Completo"
            autofocus
          />
          <small class="p-invalid" v-if="errors.nombre">{{
            errors.nombre[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado"
            ><strong>ESTADO: </strong><span class="p-invalid">*</span></label
          >
          <Dropdown
            id="estado"
            v-model="data_consultorio.estado"
            :disabled="!data_consultorio.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado..."
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-8">
          <span><strong>SELECCIONE PLATA:</strong></span>
          <Dropdown
            v-model="data_consultorio.direccion"
            :options="direcciones"
            optionLabel="label"
            optionValue="value"
            placeholder="Seleccione una ubicación..."
          />
        </div>
        <div class="field col-12 md:col-4 mt-2">
          <span><strong>COLOR:</strong></span>
          <ColorPicker v-model="data_consultorio.color" />
        </div>
        <div class="field col-12 md:col-12">
          <span
            ><strong>UBICACIONES: </strong>
            <span class="p-invalid">*</span></span
          >
          <MultiSelect
            v-model="data_consultorio.ubicaciones"
            :options="ubicaciones"
            optionLabel="nombre"
            optionValue="id"
            placeholder="Seleccione Ubicaciones"
            display="chip"
          />
        </div>
        <div class="field col-12 md:col-12">
          <span><strong>SELECCIONE TIPO DE ESTUDIO:</strong></span>
          <Dropdown
            v-model="data_consultorio.codigoTipoEstudio"
            :options="sies_tipo_estudio"
            :filter="true"
            optionLabel="nombreTipoEstudio"
            optionValue="codigoTipoEstudio"
            placeholder="Seleccione un tipo de articulo..."
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="ocultarDialog"
      />
      <Button
        :label="data_consultorio.id ? 'ACTUALIZAR' : 'GUARDAR'"
        :icon="data_consultorio.id ? 'pi pi-pencil' : 'pi pi-save'"
        class="p-button-primary p-button-lg"
        @click="saveConsultorio"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ConsultorioService from "@/service/ConsultorioService";
import ColorPicker from "primevue/colorpicker";

export default {
  components: {
    ColorPicker,
  },
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    consultorio: {
      type: Object,
      default() {
        return {};
      },
    },
    ubicaciones: {
      type: Array,
      default() {
        return [];
      },
    },
    sies_tipo_estudio: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  consultorioService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_consultorio: this.consultorio,
      enviado: false,
      estados: [
        { label: "INACTIVO", value: 0 },
        { label: "ACTIVO", value: 1 },
      ],
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      direcciones: [
        { label: "Planta Baja", value: "Planta Baja" },
        { label: "Planta Alta", value: "Planta Alta" },
      ],
    };
  },
  created() {
    this.consultorioService = new ConsultorioService();
  },
  methods: {
    ocultarDialog() {
      this.errors = {};
      this.mostrarModal = false;
      this.enviado = false;
      this.$emit("closeModal");
    },
    saveConsultorio() {
      this.errors = {};
      this.enviado = true;
      //editar consultorio
      if (this.consultorio.id) {
        let data_enviar = {
          ...this.data_consultorio,
          estado: this.data_consultorio.estado.value,
          direccion: this.data_consultorio.direccion,
          text_color: this.data_consultorio.color
            ? this.data_consultorio.color
            : "#007dd7",
          nombreTipoEstudio:
            this.sies_tipo_estudio.find(
              (x) =>
                x.codigoTipoEstudio === this.data_consultorio.codigoTipoEstudio
            ).nombreTipoEstudio || null,
        };
        this.consultorioService.updateConsultorio(data_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListado");
            this.ocultarDialog();
          }
          this.enviado = false;
        });
      } else {
        //CREANDO UN NUEVO PROVEEDOR
        let tmp = this.data_consultorio;
        this.data_consultorio = {
          ...this.data_consultorio,
          estado: this.data_consultorio.estado.value,
          direccion: this.data_consultorio.direccion,
          text_color: this.data_consultorio.color
            ? this.data_consultorio.color
            : "#007dd7",

          nombreTipoEstudio:
            this.sies_tipo_estudio.find(
              (x) =>
                x.codigoTipoEstudio === this.data_consultorio.codigoTipoEstudio
            ).nombreTipoEstudio || null,
        };
        this.consultorioService
          .sendConsultorioNew(this.data_consultorio)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_consultorio = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });
              this.$emit("actualizarListado", data.consultorio);
              this.ocultarDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    consultorio(val) {
      this.data_consultorio = val;
      /* console.log(this.data_consultorio);
      if(this.data_consultorio.id){
        this.data_consultorio.codigoTipoEstudio = this.data_consultorio.codigoTipoEstudio.toString();
      } */
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
